<template>
  <v-btn
    class="ml-3 mr-4"
    elevation="1"
    fab
    small
    @click="mini = !mini"
  >
    <v-icon>
      {{ mini ? mdiFormatListBulleted : mdiDotsVertical }}
    </v-icon>
  </v-btn>
</template>

<script>
  // Utilities
  import { sync } from 'vuex-pathify'
  import { mdiFormatListBulleted, mdiDotsVertical } from '@mdi/js'

  export default {
    name: 'DefaultDrawerToggle',
    data: () => ({
      mdiFormatListBulleted: mdiFormatListBulleted,
      mdiDotsVertical: mdiDotsVertical,
    }),
    computed: {

      mini: sync('app/mini'),
    },
  }
</script>
